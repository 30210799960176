import React from 'react'
import CountUp from 'react-countup';
import karnatakaflag from "../assets/Karnataka/Karnatakaflag.png"

const Counter = () => {
  return (
    <div>
      <div class="bg-none py-[0px] sm:py-8 mx-4">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
  <div class="flex flex-col sm:flex-row items-center justify-center 2xl:justify-center xl:justify-center md:justify-center lg:justify-center sm:justify-start">
    <div class="flex items-center justify-center mb-2 sm:mb-0">
        <img src={karnatakaflag} alt="Karnataka Flag" class="h-12 mr-2"/>
        <span class="font-poppins tracking-tight text-3xl xl:text-[2.9rem] text-gray-700 2xl:text-5xl font-semibold">ಕನ್ನಡದ No.1</span>
    </div>
    <span class="font-poppins tracking-tight text-3xl xl:text-[2.9rem] text-gray-700 2xl:text-5xl sm:ml-2 text-center sm:text-left">Tech-Skilling Platform</span>
</div>


     <br className='hidden sm:block'/>
     <br/>
    <dl class="grid grid-cols-1 gap-y-2  text-center lg:grid-cols-4 gap-0.5 ">
      <div class="mx-auto flex max-w-xs flex-col  rounded-xl">
        <dd className='order-first'><i class="fa-solid fa-users text-purple-500 text-5xl"></i></dd>
        <br/>
        <dd class="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl"><CountUp end={75000} duration={4} /> +</dd>
        <dt class="font-poppins tracking-tight text-base leading-7 text-gray-600">Active Students</dt>
      </div>
      <div class="mx-auto flex max-w-xs flex-col rounded-xl">
        <dd className='order-first'><i class="fa-brands fa-youtube text-red-600 text-5xl"></i></dd>
        <br/>
        <dd class="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl"><CountUp end={50000} duration={4} /> +</dd>
        <dt class="font-poppins tracking-tight text-base leading-7 text-gray-600">YouTube Subscribers</dt>
      </div>
      <div class="mx-auto flex max-w-xs flex-col rounded-xl">
        <dd className='order-first'><i class="fa-brands fa-instagram text-pink-600 text-5xl"></i></dd>
        <br/>
        <dd class="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl"><CountUp end={23000} duration={4} /> +</dd>
        <dt class="font-poppins tracking-tight text-base leading-7 text-gray-600">Instagram Followers</dt>
      </div>
      <div class="mx-auto flex max-w-xs flex-col rounded-xl">
        <dd className='order-first'><i class="fa-brands fa-telegram text-blue-400 text-5xl"></i></dd>
        <br/>
        <dd class="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl"><CountUp end={7000} duration={4} /> +</dd>
        <dt class="font-poppins tracking-tight text-base leading-7 text-gray-600">Telegram Followers</dt>
      </div>
    </dl>
  </div>
  <br className='hidden sm:block'/>
  <br/>
  <div class='2xl:text-2xl xl:text-2xl text-md  font-poppins tracking-tight leading-7 text-gray-600'>Our Mission: Skill 5 lakh Students by 2027 <i class="fa-solid fa-medal text-yellow-500"></i></div>
  <br/>
  <a className='hidden sm:block' href="#section1"><button className=" tracking-tight font-poppins p-[0.6rem] px-[60px] 2xl:p-[0.6rem] xl:p-[0.9rem] xl:px-[60px] md:p-[1rem] md:px-[90px] 2xl:px-[60px] bg-[#ff0055] text-white text-base md:text-lg xl:text-lg 2xl:text-lg rounded-md shadow-md shadow-red-600/50">Explore Courses <i class="fa-solid fa-angles-right"></i></button></a>
  <div className='sm:hidden space-x-0'>
           <a href="#section1"><button className="font-semibold font-poppins tracking-tight p-3 py-3 px-6 w-full m-4 2xl:ml-0 ml-0 bg-[#ff0055] text-white text-lg rounded-md shadow-md shadow-red-600/50">Explore Courses <i class="fa-solid fa-angles-right"></i></button></a>
          
         </div> 
        
</div>
    </div>
  )
}

export default Counter
